@import "../node_modules/bulma/sass/utilities/initial-variables";

@import "../node_modules/bulma/bulma";
@import "../node_modules/leaflet/dist/leaflet.css";

html, body, #fmdata, .columns, .is-fullheight {
    height: 100%;
}

.fmd-controls {
    padding: 0.25rem;
}

.fmd-legend {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background-color: #fff;
    width: 100px;
    z-index: 1072;
}